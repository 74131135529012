module services {
    'use strict';
    export module logistics {
        export class ConsignmentDocumentService implements interfaces.logistics.IConsignmentDocumentService {
            static $inject = ['$resource', 'ENV'];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {

            }           

            delete(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ConsignmentDocument/Delete", {
                    dmcId: '@dmcId'
                });
            }

           

            GetReportDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ConsignmentDocument/GetReportDropdownList", {
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }


            AddDefaultDocuments(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ConsignmentDocument/AddDefaultDocuments", {
                    conId: '@conId'
                });
            }

            GenerateRequiredDocuments(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ConsignmentDocument/GenerateRequiredDocuments", {
                    conId: '@conId'
                });
            }

        
        }
    }
    angular.module("app").service("consignmentDocumentService", services.logistics.ConsignmentDocumentService);
} 